import React from "react"
import { Link } from "gatsby"
import { getLocaleString } from "../utils"

export const PAYMENTS_COLUMNS = [
  {
    Header: "Order Id",
    accessor: "orderId",
    Cell: ({ value }) => (
      <Link className="text-blue-500" to={`/app/orders/${value}`}>
        {value}
      </Link>
    ),
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Alt Phone",
    accessor: "altPhone",
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ value }) => <span>SCR {getLocaleString(value)}</span>,
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Status",
    accessor: "status",
  },
]

export const paymentTypes = [{ name: "Cash" }, { name: "Cheque" }, { name: "Card" }, { name: "Bank Transfer" }]
