import React from "react"
import { getLocaleString } from "../utils"

export const PRODUCTS_COLUMNS = [
  {
    Header: "Product",
    accessor: "product",
  },
  {
    Header: "Glass",
    accessor: "glass",
  },
  {
    Header: "Frame",
    accessor: "frame",
  },
  {
    Header: "Width (m)",
    accessor: "width",
  },
  {
    Header: "Height (m)",
    accessor: "height",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Area (m²)",
    accessor: "area",
    Cell: ({ value }) => <span>{getLocaleString(value)}</span>,
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ value }) => <span>SCR {getLocaleString(value)}</span>,
  },
  {
    Header: "Sub Total",
    accessor: "subTotal",
    Cell: ({ value }) => <span>SCR {getLocaleString(value)}</span>,
  },
]

export const PDF_PREVIEW_PRODUCTS_COLUMNS = [
  {
    Header: "Product",
    accessor: "product",
  },
  {
    Header: "Glass",
    accessor: "glass",
  },
  {
    Header: "Frame",
    accessor: "frame",
  },
  {
    Header: "Width (m)",
    accessor: "width",
  },
  {
    Header: "Height (m)",
    accessor: "height",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Area (m²)",
    accessor: "area",
    Cell: ({ value }) => <span>{getLocaleString(value)}</span>,
  },
]

export const productOptions = [
  {
    name: "Casement Door",
    price: 3900,
    adjust: 0,
    time: 0.8,
  },
  {
    name: "Casement Window",
    price: 4000,
    adjust: 0,
    time: 0.8,
  },
  {
    name: "Fixed + Sliding",
    price: 2100,
    adjust: 0,
    time: 0.085,
  },
  {
    name: "Fixed Window",
    price: 1900,
    adjust: 0,
    time: 0.085,
  },
  {
    name: "Folding Door",
    price: 4100,
    adjust: 0,
    time: 0.8,
  },
  {
    name: "French Casement Door",
    price: 4300,
    adjust: 0,
    time: 0.8,
  },
  {
    name: "French Casement Window",
    price: 4500,
    adjust: 0,
    time: 0.8,
  },
  {
    name: "French Fixed Window",
    price: 2200,
    adjust: 0,
    time: 0.085,
  },
  {
    name: "French Folding Door",
    price: 4600,
    adjust: 0,
    time: 0.8,
  },
  {
    name: "French Sliding Door",
    price: 2300,
    adjust: 0,
    time: 0.085,
  },
  {
    name: "French Sliding Top Casement",
    price: 3500,
    adjust: 0,
    time: 1.5,
  },
  {
    name: "French Sliding Window",
    price: 2300,
    adjust: 0,
    time: 0.085,
  },
  {
    name: "Glass Only 5mm",
    price: 750,
    adjust: 0,
    time: 0.0,
  },
  {
    name: "Glass Only 6mm",
    price: 800,
    adjust: 0,
    time: 0.0,
  },
  {
    name: "Sliding Door",
    price: 2000,
    adjust: 0,
    time: 0.085,
  },
  {
    name: "Sliding Window",
    price: 2000,
    adjust: 0,
    time: 0.085,
  },
  {
    name: "Sliding + Top Casement",
    price: 2700,
    adjust: 0,
    time: 1.5,
  },
  {
    name: "Sliding + Top Fix",
    price: 2300,
    adjust: 0,
    time: 0.085,
  },
  {
    name: "Sliding + Top Sliding",
    price: 2400,
    adjust: 0,
    time: 1.7,
  },
  {
    name: "Casement + Top Casement",
    price: 4500,
    adjust: 0,
    time: 1.7,
  },
  {
    name: "Accessories",
    price: 0,
    adjust: 0,
    time: 0.0,
  },
]

export const glassOptions = [
  {
    name: "5mm Bronze",
    extra: 0,
  },
  {
    name: "5mm Clear",
    extra: 0,
  },
  {
    name: "5mm Frosted",
    extra: 100,
  },
  {
    name: "5mm Mirror",
    extra: 100,
  },
  {
    name: "5mm Tinted",
    extra: 100,
  },
  {
    name: "6mm Laminated Bronze",
    extra: 500,
  },
  {
    name: "6mm Laminated Clear",
    extra: 400,
  },
]

export const frameOptions = [
  {
    name: "Bronze",
  },
  {
    name: "White",
  },
]
