import React, { useState, useEffect, useCallback, createContext } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import { getFirestore, onSnapshot, collection, doc, getDoc, addDoc, updateDoc, query, setDoc } from "firebase/firestore"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getUser, setUser } from "../utils"

const initialStore = {
  loading: true,
  authUser: {},
  user: {},
  customers: [],
  quotations: [],
  orders: [],
  payments: [],
  users: [],
}

const StoreContext = createContext(initialStore)

const StoreProvider = ({ children }) => {
  const [store, updateStore] = useState(initialStore)
  const db = getFirestore(app)
  const auth = getAuth()

  const fetchCollection = useCallback(async (db, collectionName) => {
    try {
      const q = query(collection(db, collectionName))

      onSnapshot(
        q,
        querySnapshot => {
          const results = []
          querySnapshot.forEach(doc => {
            results.push({ ...doc.data(), id: doc.id })
          })
          updateStore(prevState => {
            return { ...prevState, [collectionName]: results, loading: false }
          })
        },
        err => {
          console.log(`Encountered error: ${err}`)
        }
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  const fetchSubCollection = async (path, update) => {
    try {
      const q = query(collection(db, path))

      onSnapshot(
        q,
        querySnapshot => {
          const results = []
          querySnapshot.forEach(doc => {
            results.push({ ...doc.data(), id: doc.id })
          })
          update(results)
        },
        err => {
          console.log(`Encountered error: ${err}`)
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const setDocument = async (collectionName, id, data) => {
    await setDoc(doc(db, collectionName, id), { ...data, created: Date.now() }, { merge: true })
  }

  const addDocument = async (collectionName, data) => {
    const docRef = await addDoc(collection(db, collectionName), { ...data, created: Date.now() })
    return docRef.id
  }

  const updateDocument = async (path, id, data) => await updateDoc(doc(db, path, id), { ...data, updated: Date.now() }, { merge: true })

  // const removeDocument = async (path, id) => await deleteDoc(doc(db, path, id))

  // Get customers
  useEffect(() => {
    store.authUser.uid && fetchCollection(db, "customers")
  }, [store.authUser, db, fetchCollection])

  // Get quotations
  useEffect(() => {
    store.authUser.uid && fetchCollection(db, "quotations")
  }, [store.authUser, db, fetchCollection])

  // Get orders
  useEffect(() => {
    store.authUser.uid && fetchCollection(db, "orders")
  }, [store.authUser, db, fetchCollection])

  // Get payments
  useEffect(() => {
    store.authUser.uid && fetchCollection(db, "payments")
  }, [store.authUser, db, fetchCollection])

  // Get users
  useEffect(() => {
    store.authUser.uid && fetchCollection(db, "users")
  }, [store.authUser, db, fetchCollection])

  useEffect(() => {
    return onAuthStateChanged(auth, authUser => {
      if (authUser) {
        updateStore(prevState => {
          return { ...prevState, authUser }
        })
      }
    })
  }, [auth])

  useEffect(() => {
    const getUserRole = async uid => {
      const userInfo = await getDoc(doc(db, "users", uid))

      if (userInfo.exists()) {
        setUser({ ...getUser(), role: userInfo.data().role })
        updateStore(prevState => {
          return { ...prevState, user: userInfo.data() }
        })
      }
    }
    store.authUser.uid && getUserRole(store.authUser.uid)
  }, [db, store.authUser])

  // console.log(store)
  return <StoreContext.Provider value={{ ...store, fetchSubCollection, setDocument, addDocument, updateDocument }}>{children}</StoreContext.Provider>
}

export default StoreContext

export { StoreProvider }
