import React from "react"
import { Link } from "gatsby"
import { getLocaleTimeFormat } from "../utils"

export const QUOTATIONS_COLUMNS = [
  {
    Header: "Id",
    accessor: "id",
    Cell: ({ value }) => (
      <Link className="text-blue-500" to={`/app/quotations/${value}`}>
        {value}
      </Link>
    ),
  },
  {
    Header: "Created",
    accessor: "created",
    Cell: ({ value }) => <span>{getLocaleTimeFormat(value)}</span>,
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Alt Phone",
    accessor: "altPhone",
  },
]
