import { getAuth, signOut } from "firebase/auth"
import { navigate } from "gatsby"

export const isBrowser = () => typeof window !== "undefined"
export const getUser = () => (isBrowser() && window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")) : {})
export const setUser = user => isBrowser() && window.localStorage.setItem("user", JSON.stringify(user))

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.email
}

export const logout = () => {
  const auth = getAuth()
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      setUser({})
      navigate("/login")
    })
    .catch(error => {
      // An error happened.
    })
}
