export const getPanelWidth = (panel, productWidth) => {
  switch (panel) {
    case 2:
      return (productWidth - 2) / 2
    case 3:
      return (productWidth + 36) / 3
    case 4:
      return productWidth / (4 + 5)

    default:
      return productWidth
  }
}
