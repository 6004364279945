import { ORDERS_SALES_STAGE } from "../data"
import { getUser } from "./auth"

export const getOrdersByRole = orders => {
  const { role } = getUser()
  const technicianSales = [ORDERS_SALES_STAGE[1].name, ORDERS_SALES_STAGE[2].name, ORDERS_SALES_STAGE[3].name]
  switch (role) {
    case "technician":
      return orders.filter(order => technicianSales.includes(order.salesStage))
    default:
      return orders
  }
}
