export const CUSTOMERS_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Alt Phone",
    accessor: "altPhone",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "District",
    accessor: "district",
  },
  {
    Header: "Address",
    accessor: "address",
  },
]
