import { ClipboardListIcon, HomeIcon, UsersIcon, CollectionIcon, CreditCardIcon } from "@heroicons/react/outline"

export const navigation = [
  { name: "Dashboard", to: "/app/dashboard", icon: HomeIcon },
  { name: "Customers", to: "/app/customers", icon: UsersIcon },
  { name: "Quotations", to: "/app/quotations", icon: ClipboardListIcon },
  { name: "Orders", to: "/app/orders", icon: CollectionIcon },
  { name: "Payments", to: "/app/payments", icon: CreditCardIcon },
]

export const userNavigation = [
  { name: "Your Profile", to: "/app/dashboard" },
  { name: "Settings", to: "/app/dashboard" },
  { name: "Sign out", to: "/app/dashboard" },
]
