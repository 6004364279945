export const USERS_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: "role",
  },
]

export const roleOptions = [
  {
    name: "user",
  },
  {
    name: "manager",
  },
  {
    name: "sales",
  },
  {
    name: "technician",
  },
]
