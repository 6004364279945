import React from "react"
import { ToastContainer } from "react-toastify"

import { StoreProvider } from "./src/context/storeContext"

import "react-toastify/dist/ReactToastify.css"
import "./src/styles/global.css"

const wrapRootElement = ({ element }) => (
  <StoreProvider>
    {element}
    <ToastContainer />
  </StoreProvider>
)

export { wrapRootElement }
