import { navigation } from "../data"

export const getNavigation = role => {
  switch (role) {
    case "manager":
      return navigation
    case "sales":
      return navigation.filter(nav => nav.name !== "Payments")
    case "technician":
      return navigation.filter(nav => ["Dashboard", "Orders"].includes(nav.name))
    default:
      return navigation
  }
}
