import React from "react"
import { Link } from "gatsby"
import { getLocaleTimeFormat } from "../utils"

export const ORDERS_COLUMNS = [
  {
    Header: "Id",
    accessor: "id",
    Cell: ({ value }) => (
      <Link className="text-blue-500" to={`/app/orders/${value}`}>
        {value}
      </Link>
    ),
  },
  {
    Header: "Created",
    accessor: "created",
    Cell: ({ value }) => <span>{getLocaleTimeFormat(value)}</span>,
  },
  {
    Header: "Sales Stage",
    accessor: "salesStage",
  },
  {
    Header: "Build Stage",
    accessor: "buildStage",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Alt Phone",
    accessor: "altPhone",
  },
]

export const ORDERS_SALES_STAGE = [
  {
    name: "1 - Partially funded",
  },
  {
    name: "2 - Measure completed",
  },
  {
    name: "3 - Fully paid",
  },
  {
    name: "4 - Scheduled",
  },
  {
    name: "5 - Installed",
  },
]

export const ORDERS_BUILD_STAGE = [
  {
    name: "1 - Cut started",
  },
  {
    name: "2 - Cut completed",
  },
  {
    name: "3 - Assembled",
  },
]

export const ORDERS_PANNELS = [
  {
    name: "2",
  },
  {
    name: "3",
  },
  {
    name: "4",
  },
]
